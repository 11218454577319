import React, { useEffect } from 'react';
import ewasteauditdetails from './ewasteauditdetails.png';
import './EwasteAudit.css';

function EwasteAudit() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="esg-container">
      <h1 className="esg-title">E-WASTE AUDIT AND VALUATION SERVICES</h1>

      <p className="esg-description">
      In today's fast-paced digital world, organizations are generating electronic waste (e-waste) at unprecedented rates. Proper management of e-waste is essential not only for protecting the environment but also for recovering valuable resources. At Hines Sustainability, we specialize in providing comprehensive e-waste valuation and auditing services to help businesses manage their electronic waste efficiently and responsibly.
      </p>
      <div style={{display:"flex","justify-content":"center"}}>
        <img src={ewasteauditdetails} alt="ESG reporting services" />
      </div>
      <h2 className="esg-subtitle">E-Waste Valuation: Maximizing Value from Discarded Electronics</h2>
      <p className="esg-description">
      Understanding the financial value of e-waste is a critical step for any organization aiming to minimize waste and maximize recovery. Our valuation services assess the worth of discarded electronics based on several factors:  
      </p>

      <div className="esg-reporting-process">
      <ul className="esg-benefits-list">
        <li><strong>Material Composition:</strong>Many electronic devices contain valuable materials such as gold, silver, copper, and reusable parts. 
        Our experts evaluate the quality and quantity of these materials to provide a precise estimate of their worth.</li>
        <li><strong>Device Condition:</strong> Not all e-waste is scrap. Functional or repairable electronics can be refurbished and resold, significantly increasing their value. We help identify these opportunities to maximize returns for your business.</li>
        <li><strong>Market Pricing:</strong>The value of recoverable materials is influenced by fluctuating market prices for metals and plastics. Hines Sustainability keeps track of these trends to ensure accurate valuations for your e-waste.</li>
        <li><strong>Processing Costs:</strong>Efficient e-waste processing is essential to profitability. We evaluate the costs of collection, transportation, and recycling, offering insights into how these processes can be optimized.</li>
        <li><strong>Regulatory Incentives:</strong>We also help businesses navigate government regulations and take advantage of any financial incentives, such as tax breaks or subsidies, for responsible e-waste management.</li>
      </ul>
      </div>
      
      <h2 className="esg-subtitle">E-Waste Auditing: Ensuring Compliance and Sustainability</h2>
      <div className="esg-reporting-process">
      <p className="esg-description">
      At Hines Sustainability, we don't just help you understand the value of your e-waste — we also ensure that it's being managed in a compliant and environmentally friendly manner. Our e-waste auditing services provide organizations with a detailed overview of their e-waste management practices, focusing on:
      </p>
      </div>
      <div className="esg-reporting-process">
      <h3 className="esg-section-title">Inventory Management:</h3>
      <p className="esg-description">
       We track the types and quantities of e-waste generated by your organization, helping you optimize collection and processing efforts.
      </p>
      </div>
      <div className="esg-reporting-process">
      <h3 className="esg-section-title">Regulatory Compliance:</h3>
      <p className="esg-description">
      E-waste management is governed by strict environmental regulations. Our audits ensure your organization is compliant with all relevant laws, reducing the risk of fines and enhancing your sustainability credentials.
      </p>
      </div>
      <div className="esg-reporting-process">
      <h3 className="esg-section-title">Recycling Efficiency:</h3>
      <p className="esg-description">
      Our auditing process evaluates how efficiently your e-waste is being recycled, identifying opportunities to improve recovery rates and reduce the volume of waste sent to landfills.
      </p>
      </div>
      <div className="esg-reporting-process">
      <h3 className="esg-section-title">Environmental Impact:</h3>
      <p className="esg-description">
      We assess the environmental footprint of your e-waste management processes, including emissions, energy consumption, and potential pollution, helping you meet sustainability goals.
      </p>
      </div>
      <div className="esg-reporting-process">
      <h3 className="esg-section-title">Data Security:</h3>
      <p className="esg-description">
      Safeguarding sensitive information is a top priority. We ensure that any electronic devices containing data are properly wiped or destroyed to protect your organization from data breaches.
      </p>
      </div>
      <div className="esg-reporting-process">
      <h3 className="esg-section-title">Cost Control: </h3>
      <p className="esg-description">
      In addition to compliance and environmental benefits, our auditing services also focus on the financial aspects of e-waste management. We identify areas for cost savings while improving overall efficiency.
      </p>
      </div>

      <h2 className="esg-subtitle">Partner with Hines Sustainability for Effective E-Waste Audit and Valuation</h2>
      
      <p className="esg-conclusion">
      At Hines Sustainability, we are committed to helping organizations manage their e-waste in a way that is both environmentally responsible and financially beneficial. Our expert valuation and auditing services ensure that you get the most value from your e-waste while staying compliant with regulations and minimizing your environmental impact.
      </p>
    </div>
  );
} 

export default EwasteAudit;

