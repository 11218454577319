import React, { useEffect } from 'react';
import plasticdetails from './plastic-details.png';
import './Plastic.css';

function Plastic() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="plastic-container">
      <h1 className="plastic-title">Plastic Waste Management Services</h1>
      
      <p className="plastic-description">
        At Hines Sustainability, we specialize in effective plastic waste management solutions to tackle the escalating issue of plastic pollution. Our collaboration with certified recycling facilities across India allows us to convert plastic waste into valuable resources, fostering environmental sustainability and promoting a circular economy.
      </p>
      <div style={{display:"flex","justify-content":"center"}}>
        <img src={plasticdetails} alt="Plastic Management" />
      </div>
      <h2 className="plastic-subtitle">Why is Plastic Recycling Important?</h2>
      <ul className="plastic-benefits-list">
        <li><strong>Environmental Protection:</strong> Plastic waste poses significant risks to ecosystems, wildlife, and human health. By recycling plastic, we divert it from landfills and oceans, reducing environmental hazards.</li>
        <li><strong>Resource Conservation:</strong> Recycling plastic conserves natural resources, decreasing the demand for virgin materials and minimizing energy usage in manufacturing processes.</li>
        <li><strong>Economic Growth:</strong> The plastic recycling sector generates employment opportunities, drives innovation, and bolsters local economies by advancing green technologies.</li>
        <li><strong>Circular Economy:</strong> By reintegrating recycled plastics into the production cycle, we support a circular economy that emphasizes sustainability and waste reduction.</li>
      </ul>

      <h2 className="plastic-subtitle">Our Plastic Waste Management Process</h2>
      <div className = "plastic-waste-management">
      <h3 className="plastic-step-title">1. Collection and Transportation of Plastic Waste</h3>
      <p className="plastic-step-content">
        We manage the collection of plastic waste from diverse sources, including households, businesses, and industrial sites, through partnerships with local waste management organizations and recyclers across India. Our efficient logistics network ensures the safe transportation of collected plastics to recycling facilities, adhering to environmental regulations.
      </p>
      </div>
      <div className = "plastic-waste-management">
      <h3 className="plastic-step-title">2. Sorting and Cleaning of Plastic Materials</h3>
      <p className="plastic-step-content">
        At our recycling partners’ facilities, plastics are meticulously sorted by resin type (e.g., PET, HDPE, LDPE, PP) and color using cutting-edge technologies like optical sorters and manual sorting. After sorting, the plastics are thoroughly cleaned to remove labels, dirt, and residues, ensuring high-quality recycled material.
      </p>
      </div>
      <div className = "plastic-waste-management">
      <h3 className="plastic-step-title">3. Shredding and Granulation of Recycled Plastics</h3>
      <p className="plastic-step-content">
        Cleaned plastics are processed through industrial shredders, breaking them down into smaller pieces or flakes. These are then transformed into uniform granules suitable for new plastic products.
      </p>
      </div>
      <div className = "plastic-waste-management">
      <h3 className="plastic-step-title">4. Production of High-Quality Recycled Plastic Products</h3>
      <p className="plastic-step-content">
        The granulated plastic is converted into a wide array of products, including packaging materials, consumer goods, textiles, construction materials, agricultural films, and automotive parts. All products undergo rigorous testing to meet industry quality standards.
      </p>
      </div>
      <div className = "plastic-waste-management">
      <h3 className="plastic-step-title">5. Extended Producer Responsibility (EPR) Compliance</h3>
      <p className="plastic-step-content">
        We assist manufacturers and brands in fulfilling their Extended Producer Responsibility (EPR) obligations under India’s Plastic Waste Management Rules, including creating systems for the collection and recycling of plastic waste generated by their products.
      </p>
      </div>
      <div className = "plastic-waste-management">
      <h3 className="plastic-step-title">6. Regulatory Compliance and Certification</h3>
      <p className="plastic-step-content">
        Our recycling partners comply with local regulations and industry standards, ensuring environmentally responsible practices throughout the recycling process. Clients receive detailed reports on material recovery rates and environmental impacts, supporting their sustainability commitments.
      </p>
      </div>
      <h2 className="plastic-subtitle">Why Choose Hines Sustainability for Plastic Waste Management?</h2>
      <ul className="plastic-benefits-list">
        <li>Collaboration with Leading Indian Recycling Experts.</li>
        <li>Advanced Recycling Technologies for efficient sorting, shredding, and granulation.</li>
        <li>Commitment to sustainable practices and a greener future.</li>
        <li>Comprehensive service offerings, from collection to production of high-quality recycled products.</li>
      </ul>

      <p className="plastic-description">
        By partnering with Hines Sustainability, you contribute to a cleaner planet while benefiting from our expertise and reliable network of recycling partners in India.
      </p>
    </div>
  );
}

export default Plastic;
